import { DataGrid } from "@mui/x-data-grid";
import { subHistoryColumns } from "../../datatablesource";
import { useEffect, useState } from "react";

import fetchURLForm from "../../../../functions/fetchURLForm";
import { fetchUrl } from "../../../../functions/fetchUrl";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const SubPostedTimWe = ({ table }) => {
  // console.log("Table: " + table)
  useState("Loading...");
  const today = new Date();

  const [dataOoredoo, setDataOoredoo] = useState([]);
  const [dataOmantel, setDataOmantel] = useState([]);

  const [dataSTC, setDataSTC] = useState([]);
  const [dataBatelco, setDataBatelco] = useState([]);

  const [countOM, setCountOM] = useState(0);
  const [countOoredoo, setCountOoredoo] = useState(0);
  const [countOmantel, setCountOmantel] = useState(0);
  console.log(countOoredoo)
  console.log(countOmantel)

  const [dataMobily, setDataMobily] = useState([]);
  const [countSA, setCountSA] = useState(0);
  const [countMobily, setCountMobily] = useState(0);

  const [countBH, setCountBH] = useState(0);
  const [countSTC, setCountSTC] = useState(0);
  const [countBatelco, setCountBatelco] = useState(0);

  const [valueDateFrom, setValueDateFrom] = useState(today);
  const [valueDateTo, setValueDateTo] = useState(today);

  const columns = [
    {
      field: "msisdn",
      headerName: "msisdn",
      width: 110,
    },
    {
      field: "operator",
      headerName: "operator",
      width: 80,
    },
    {
      field: "isSent",
      headerName: "isSent",
      width: 80,
    },
  ];

  // console.log(dataOoredoo);

  useEffect(() => {
    // setDataOoredoo([]);
    // setDataOmantel([]);
    //"valueDateTo": "01-20-2023"

    const monthFromNumber = (valueDateFrom.getMonth() + 1).toString().padStart(2, "0");
    const dayFromNumber = valueDateFrom.getDate().toString().padStart(2, "0");
    const yearFromNumber = valueDateFrom.getFullYear();

    const monthToNumber = (valueDateTo.getMonth() + 1).toString().padStart(2, "0");
    const dayToNumber = valueDateTo.getDate().toString().padStart(2, "0");
    const yearToNumber = valueDateTo.getFullYear();

    const dateFrom = monthFromNumber + "-" + dayFromNumber + "-" + yearFromNumber;
    const dateTo = monthToNumber + "-" + dayToNumber + "-" + yearToNumber;

    const bodySend = {
      valueDate: dateFrom,
      valueToDate: dateTo,
    };

    const fetchData = async () => {
      const response = await fetchUrl({
        apiName: table,
        method: "POST",
        reqBody: bodySend
      });
      if (response.success) {
        // console.log(response.result);
        if (table === "koraclubtimwe/getPostedOM" || table === "converseaitimwe/getPostedOM") {
          // const totalPriceOM = response.result.reduce((sum, item) => sum + (item.price * item.count), 0);

          const ooredooData = response.result.filter(item => item.operator.includes('ooredoo'));
          const omantelData = response.result.filter(item => item.operator.includes('omantel'));

          const countOoredoo = ooredooData.length;
          const countOmantel = omantelData.length;

          setDataOoredoo(ooredooData);
          setDataOmantel(omantelData);

          setCountOoredoo(countOoredoo)
          setCountOmantel(countOmantel)

          setCountOM(response.result.length);
        } else if (table === "scoreTimwe/getPostedSA" || table === "beautyTimwe/getPostedSA" || table === "assistantTimwe/getPostedSA") {

          const mobilyData = response.result.filter(item => item.operator.includes('mobily'));
          // const omantelData = response.result.filter(item => item.operator.includes('omantel'));

          const countMobily = mobilyData.length;
          // const countOmantel = omantelData.length;

          setDataMobily(mobilyData);
          // setDataOmantel(omantelData);

          setCountMobily(countMobily)
          // setCountOmantel(countOmantel)

          setCountSA(response.result.length);
        } else {
          // const totalPriceBH = response.result.reduce((sum, item) => sum + (item.price * item.count), 0);

          const stcData = response.result.filter(item => item.operator.includes('stc'));
          const batelcoData = response.result.filter(item => item.operator.includes('batelco'));

          const countSTC = stcData.length;
          const countBatelco = batelcoData.length;

          setDataSTC(stcData);
          setDataBatelco(batelcoData);

          setCountSTC(countSTC)
          setCountBatelco(countBatelco)

          setCountBH(response.result.length)
        }
      }
    };
    fetchData();




  }, [table, valueDateFrom, valueDateTo]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: "inline-block", margin: "0 10px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From:"
              value={valueDateFrom}
              onChange={(newValue) => {
                setValueDateFrom(new Date(newValue));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div style={{ display: "inline-block", margin: "0 10px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To:"
              value={valueDateTo}
              onChange={(newValue) => {
                setValueDateTo(new Date(newValue));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>

      {table === "koraclubtimwe/getPostedOM" || table === "converseaitimwe/getPostedOM" ?
        <>
          <h4 style={{ marginTop: '10px' }}>Total Count: {countOM} OMR</h4>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>
              <h4 style={{ marginTop: '10px' }}>Ooredoo: Total {countOoredoo} OMR</h4>
              <DataGrid
                className="ml-1"
                rows={dataOoredoo}
                columns={columns}
                pageSize={8}
                rowsPerPageOptions={[5]}
                getRowId={(data) => data.id}
                style={{ height: "60vh", marginLeft: "50px" }}
              />
            </div>

            <div style={{ width: '100%' }}>
              <h4 style={{ marginTop: '10px' }}>Omantel: Total {countOmantel} OMR</h4>
              <DataGrid
                className="ml-1"
                rows={dataOmantel}
                columns={columns}
                pageSize={8}
                rowsPerPageOptions={[5]}
                getRowId={(data) => data.id}
                style={{ height: "60vh", marginLeft: "50px" }}
              />
            </div>
          </div>
        </>
        : table === "scoreTimwe/getPostedSA" || table === "beautyTimwe/getPostedSA" || table === "assistantTimwe/getPostedSA" ?
          <>
            <h4 style={{ marginTop: '10px' }}>Total Count: {countSA} SAR</h4>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '100%' }}>
                <h4 style={{ marginTop: '10px' }}>Mobily: Total {countMobily} SAR</h4>
                <DataGrid
                  className="ml-1"
                  rows={dataMobily}
                  columns={columns}
                  pageSize={8}
                  rowsPerPageOptions={[5]}
                  getRowId={(data) => data.id}
                  style={{ height: "60vh", marginLeft: "50px" }}
                />
              </div>

              {/* <div style={{ width: '100%' }}>
                <h4 style={{ marginTop: '10px' }}>Omantel: Total {countOmantel} OMR</h4>
                <DataGrid
                  className="ml-1"
                  rows={dataOmantel}
                  columns={columns}
                  pageSize={8}
                  rowsPerPageOptions={[5]}
                  getRowId={(data) => data.id}
                  style={{ height: "60vh", marginLeft: "50px" }}
                />
              </div> */}
            </div>
          </>
          :
          <>
            <h4 style={{ marginTop: '10px' }}>Total Count: {countBH} BHD</h4>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '100%' }}>
                <h4 style={{ marginTop: '10px' }}>STC: Total {countSTC} BHD</h4>
                <DataGrid
                  className="ml-1"
                  rows={dataSTC}
                  columns={columns}
                  pageSize={8}
                  rowsPerPageOptions={[5]}
                  getRowId={(data) => data.id}
                  style={{ height: "60vh", marginLeft: "50px" }}
                />
              </div>

              <div style={{ width: '100%' }}>
                <h4 style={{ marginTop: '10px' }}>Batelco: Total {countBatelco} BHD</h4>
                <DataGrid
                  className="ml-1"
                  rows={dataBatelco}
                  columns={columns}
                  pageSize={8}
                  rowsPerPageOptions={[5]}
                  getRowId={(data) => data.id}
                  style={{ height: "60vh", marginLeft: "50px" }}
                />
              </div>
            </div>
          </>

      }
    </>

  );
};

export default SubPostedTimWe;
