import React from "react";
import AdminSidebar from "../components/sidebar/AdminSidebar";
import SuperAdminSidebar from "../components/sidebar/SuperAdminSidebar";
import AccountantSidebar from "../components/sidebar/AccountantSidebar";
import { Route, Routes } from "react-router-dom";
import Home from "../components/pages/home/Home";
// import SubWidget from "../components/pages/categories/sub_widget/SubWidget";
// import SubWidgetstore from "../components/pages/store/sub_widget/SubWidget";
import SubWidgetsAll from "../components/pages/all/sub_widget/SubWidget";

import SubWidgetTimWe from "../components/pages/all/sub_widget/SubWidgetTimWe";
import SubWidgetTimWeSA from "../components/pages/all/sub_widget/SubWidgetTimWeSA";

import StickyNoteMain from "../components/pages/sticky_note/StickyNoteWidget";
import BillablePosted from "../components/pages/billablePosted/BillablePosted";
import NonBillablePosted from "../components/pages/nonbillablePosted/NonBillablePosted";
import AdvertisingNetwork from "../components/pages/advertisingNetwork/AdversingNetwork";
import AdvertisingBillablePosted from "../components/pages/AdvertisingBillablePosted";
import AllLogs from "../components/pages/AllServicesAdv/AllLogs";

import Services from "../components/pages/services/Service";

// import StoreApproval from "../components/pages/store/StoreApproval";
// import Store from "../components/pages/store/Store";

import StoreUsers from "../components/pages/stores_users/Users";
import Categories from "../components/pages/categories/Categories";
import SubWidgetCategory from "../components/pages/categories/sub_widget/SubWidget";
import CreateAccount from "../components/pages/create_account/CreateAccount";
import Header from "../components/header/Header";
import NotFound from "../components/not_found_component/NotFound";
import "./layout.css";
import Invoice from "../components/pages/orders/invoices/index";
import StoresCategories from "../components/pages/stores-categories/StoresCategories";

const Layout = ({ role, Username, imageUser }) => {
  const sidebarFunction = () => {
    switch (role) {
      case "superadmin":
        return <SuperAdminSidebar />;
      case "admin":
        return <AdminSidebar />;
      case "accountant":
        return <AccountantSidebar />;
    }
  };
  return (
    <>
      <div className="container-right">
        <Header title={role} name={Username} imageUser={imageUser} />

        <div className="row">
          {sidebarFunction()}

          <Routes>
            <Route
              path="home"
              element={role === "superadmin" ? <Home /> : <NotFound />}
            />

            {/* <Route path='categories/:id' element={<AddCategory />} /> */}

            <Route
              path="adduser"
              element={role === "superadmin" ? <CreateAccount /> : <NotFound />}
            />

            {/* 
            <Route
              path="services"
              element={
                role === 'superadmin' || role === 'admin' ? (
                  <Services />
                ) : (
                  <NotFound />
                )
              }
            /> */}
            {/* <Route
              path="billableposted"
              element={
                role === 'superadmin' || role === 'admin' ? (
                  <BillablePosted />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="nonbillableposted"
              element={
                role === 'superadmin' || role === 'admin' ? (
                  <NonBillablePosted />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="billablepostedbynetwork"
              element={
                role === 'superadmin' || role === 'admin' ? (
                  <BillablePostedByNetwork />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="advertisingnetwork"
              element={
                role === 'superadmin' || role === 'admin' ? (
                  <AdvertisingNetwork />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="AdvertisingInvoices"
              element={
                role === 'superadmin' || role === 'admin' ? (
                  <AdvertisingInvoices />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="AdvertisingOffers"
              element={
                role === 'superadmin' || role === 'admin' ? (
                  <AdvertisingOffers />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="AdvertisingBillablePosted"
              element={
                role === 'superadmin' || role === 'admin' ? (
                  <AdvertisingBillablePosted />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="AdvertisingNonBillablePosted"
              element={
                role === 'superadmin' || role === 'admin' ? (
                  <AdvertisingNonBillablePosted />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="extratime"
              element={
                role === 'superadmin' ? <ExtraTimeSubWidget /> : <NotFound />
              }
            />
            <Route
              path="filesheild"
              element={role === 'superadmin' ? <FileSheild /> : <NotFound />}
            />
            <Route
              path="gamesportal"
              element={role === 'superadmin' ? <GamesPortal /> : <NotFound />}
            />
            <Route
              path="gombsgom"
              element={role === 'superadmin' ? <GomBsGom /> : <NotFound />}
            />
            <Route
              path="gymnslim"
              element={role === 'superadmin' ? <GymNSLim /> : <NotFound />}
            />
            <Route
              path="islamicnote"
              element={role === 'superadmin' ? <IslamicNote /> : <NotFound />}
            />
            <Route
              path="koraclub"
              element={role === 'superadmin' ? <KoraClub /> : <NotFound />}
            />
            <Route
              path="mobileboxes"
              element={role === 'superadmin' ? <MobileBoxes /> : <NotFound />}
            />
            <Route
              path="mobofort"
              element={role === 'superadmin' ? <Mobofort /> : <NotFound />}
            />
            <Route
              path="muslimsgate"
              element={role === 'superadmin' ? <MuslimsGate /> : <NotFound />}
            />
            <Route
              path="smsmapp"
              element={role === 'superadmin' ? <SmsmApp /> : <NotFound />}
            />
            <Route
              path="vipgym"
              element={role === 'superadmin' ? <VipGym /> : <NotFound />}
            />
            <Route
              path="whatsfile"
              element={role === 'superadmin' ? <WhatsFile /> : <NotFound />}
            />
            <Route
              path="dailybeauty"
              element={role === 'superadmin' ? <DailyBeauty /> : <NotFound />}
            />
            <Route
              path="kidspreschool"
              element={role === 'superadmin' ? <KidsPreSchool /> : <NotFound />}
            />
            <Route
              path="bnfootball"
              element={role === 'superadmin' ? <BnFootball /> : <NotFound />}
            />
            <Route
              path="filesguard"
              element={role === 'superadmin' ? <FilesGuard /> : <NotFound />}
            />
            <Route
              path="islamicreminder"
              element={
                role === 'superadmin' ? <IslamicReminder /> : <NotFound />
              }
            />

            <Route
              path="pets"
              element={role === 'superadmin' ? <Pets /> : <NotFound />}
            />

            <Route
              path="oxford"
              element={role === 'superadmin' ? <Oxford /> : <NotFound />}
            />

            <Route
              path="chefRecipes"
              element={role === 'superadmin' ? <ChefRecipes /> : <NotFound />}
            /> */}

            {/* <Route
              path="all"
              element={role === "superadmin" ? <All /> : <NotFound />}
            /> */}
            <Route
              path="billableposted"
              element={
                role === "superadmin" ? <BillablePosted /> : <NotFound />
              }
            />
            <Route
              path="nonbillableposted"
              element={
                role === "superadmin" ? <NonBillablePosted /> : <NotFound />
              }
            />
            <Route
              path="advertisingnetwork"
              element={
                role === "superadmin" ? <AdvertisingNetwork /> : <NotFound />
              }
            />
            <Route
              path="AdvertisingBillablePosted"
              element={
                role === "superadmin" ? (
                  <AdvertisingBillablePosted table="services/allbillablepostedtotal" />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="AdvertisingNonBillablePosted"
              element={
                role === "superadmin" ? (
                  <AdvertisingBillablePosted table="services/allnonbillablepostedtotal" />
                ) : (
                  <NotFound />
                )
              }
            />

            <Route
              path="advertisement"
              element={
                role === "superadmin" || role === "admin" ? (
                  <AllLogs table="services" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />

            <Route
              path="services"
              element={role === "superadmin" ? <Services /> : <NotFound />}
            />

            <Route
              path="bnfootball"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="bnfootball" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="extratime"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="extratime" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />

            <Route
              path="shootkoora"
              element={
                role === "superadmin" || role === "admin" ? (
                  <SubWidgetsAll table="shootkoora" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />

            <Route
              path="filesguard"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="filesguard" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="filesheild"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="filesheild" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="sheild"
              element={
                role === "superadmin" || role === "admin" ? (
                  <SubWidgetsAll table="sheild" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="securesafe"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="securesafe" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="gamesportal"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="gamesportal" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="gombsgom"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="gombsgom" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="gymnslim"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="gymnslim" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="islamicnote"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="islamicnote" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="alsirat"
              element={
                role === "superadmin" || role === "admin" ? (
                  <SubWidgetsAll table="alsirat" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="plantid"
              element={
                role === "superadmin" || role === "admin" ? (
                  <SubWidgetsAll table="plantid" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="womencare"
              element={
                role === "superadmin" || role === "admin" ? (
                  <SubWidgetsAll table="womencare" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="foodrecipes"
              element={
                role === "superadmin" || role === "admin" ? (
                  <SubWidgetsAll table="foodrecipes" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="islamicreminder"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="islamicreminder" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="koraclub"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="koraclub" />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="koraclubtimwe"
              element={
                role === "superadmin" ? (
                  <SubWidgetTimWe table="koraclubtimwe" />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="converseaitimwe"
              element={
                role === "superadmin" ? (
                  <SubWidgetTimWe table="converseaitimwe" />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="chefrecipestimwe"
              element={
                role === "superadmin" ? (
                  <SubWidgetTimWe table="chefrecipestimwe" />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="scoretimwe"
              element={
                role === "superadmin" ? (
                  <SubWidgetTimWeSA table="scoreTimwe" />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="beautytimwe"
              element={
                role === "superadmin" ? (
                  <SubWidgetTimWeSA table="beautyTimwe" />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="assistanttimwe"
              element={
                role === "superadmin" ? (
                  <SubWidgetTimWeSA table="assistantTimwe" />
                ) : (
                  <NotFound />
                )
              }
            />

            <Route
              path="mobileboxes"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="mobileboxes" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="mobofort"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="mobofort" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="muslimsgate"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="muslimsgate" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="smsmapp"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="smsmapp" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="vipgym"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="vipgym" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="whatsfile"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="whatsfile" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />

            <Route
              path="dailybeauty"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="dailybeauty" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="kidspreschool"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="kidspreschool" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="pets"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="pets" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="oxford"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="oxford" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="chefRecipes"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="chefRecipes" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="subAndWin"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="subAndWin" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="phoneCleaner"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="phoneCleaner" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="converseAI"
              element={
                role === "superadmin" ? (
                  <SubWidgetsAll table="converseAI" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="islamnquran"
              element={
                role === "superadmin" || role === "accountant" ? (
                  <SubWidgetsAll table="islamnquran" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="stickyNote"
              element={
                role === "superadmin" || role === "accountant" ? (
                  <StickyNoteMain table="stickyNote" role={role} />
                ) : (
                  <NotFound />
                )
              }
            />

            {/* <Route
              path="categories"
              element={role === "superadmin" ? <Categories /> : <NotFound />}
            />
            <Route
              path="categories/:id"
              element={
                role === "superadmin" ? <SubWidgetCategory /> : <NotFound />
              }
            />

            <Route
              path="store"
              element={role === "superadmin" ? <Store /> : <NotFound />}
            />
            <Route
              path="store/:id"
              element={
                role === "superadmin" ? <SubWidgetstore /> : <NotFound />
              }
            />
            <Route
              path="storeapproval"
              element={role === "superadmin" ? <StoreApproval /> : <NotFound />}
            />
            <Route
              path="storeapproval/:id"
              element={
                role === "superadmin" ? <SubWidgetstore /> : <NotFound />
              }
            /> */}

            <Route
              path="menu"
              element={
                role === "superadmin" || role === "admin" ? (
                  <StoresCategories />
                ) : (
                  <NotFound />
                )
              }
            />
            {/* <Route path='forget-password' element={<ForgetPassword/>}/> */}
            {/* ACCOUNTATANT ROUTES  */}
            {/* <Route path="accountant-home" element={<AccountantHome />} />
            <Route path="accountant-stores" element={<AccountantStores />} />
            <Route path="accountant-drivers" element={<AccountantDrivers />} />
            <Route path="accountant-wallet" element={<Wallet />} /> */}
          </Routes>
        </div>
      </div>
    </>
  );
};

export default Layout;
