import "./subwidget.css";
import { useState, useEffect } from "react";
// import AddStoreBox from "../AddStoreBox";
// import NotFound from "../../../not_found_component/NotFound";
import Advertisment from "./Advertisment";
import ActiveSub from "./ActiveSub";
import PostedBillable from "./PostedBillable";
import PostedNonBillable from "./PostedNonBillable";
import PostedBillableDaily from "./PostedBillableDaily";
import PostedNonBillableDaily from "./PostedNonBillableDaily";
import PostedMonthly from "./PostedMonthly";
import PostedMonthlyNonBillable from "./PostedMonthlyNonBillable";
import ActiveNonBillable from "./ActiveNonBillable";
import OperatorResponseDaily from "./OperatorResponseDaily";
import OperatorResponseMonthly from "./OperatorResponseMonthly";
import CountDailySubByOperator from "./CountDailySubByOperator"; // dublicated
import CountDailyPostedByOperator from "./CountDailyPostedByOperator";
import CountWeeklyPinReqCon from "./CountWeeklyPinReqCon";
import WeeklyVisitors from "./WeeklyVisitors";
import SubHistory from "./SubHistory";
import SubHistoryAll from "./SubHistoryAll";
import AllSub from "./AllSub";
import InfoCPI from "./InfoCPI";
import SubAndRenew from "./SubAndRenew";
import ApiSub from "./ApiSub";

import CountSubUnSubDaily from "./CountSubUnSubDaily";
const SubWidget = ({ table, role }) => {
  const colors = ["red", "green", "blue", "brown"];
  const randomIndex = Math.floor(Math.random() * colors.length);
  const randomColor = colors[randomIndex];

  // useEffect(() => {
  //   window.location.reload();
  // }, []);

  return (
    <div
      className="col-10 "
      style={{
        position: "relative",
        left: "250px",
        top: "100px",
      }}
    >
      <div>
        <div className="row  p-3"></div>
        {role !== "accountant" && (
          <div className="row">
            <div className="col-11 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Advertisment
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <Advertisment table={table + "/pageViews"} />
              </div>
            </div>
          </div>
        )}

        {table === "alsirat" && (
          <div className="row">
            <div className="col-11 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                API SUB
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <ApiSub table={table + "/apiSub"} />
              </div>
            </div>
          </div>
        )}

        {table === "shootkoora" && role !== "accountant" && (
          <div className="row">
            <div className="col-11 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                API SUB
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <ApiSub table={table + "/apiSub"} />
              </div>
            </div>
          </div>
        )}

        {role !== "accountant" && (
          <div className="row">
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                ActiveSub
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <ActiveSub table={table} />
              </div>
            </div>
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Daily Report{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <CountSubUnSubDaily table={table + "/countSubUnSub"} />
              </div>
            </div>
          </div>
        )}

        {role !== "accountant" && (
          <div className="row">
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Posted Billable{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <PostedBillable table={table + "/billableposted"} />
              </div>
            </div>

            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Posted Non-Billable{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <PostedNonBillable table={table + "/nonbillableposted"} />
              </div>
            </div>
          </div>
        )}


        {role !== "accountant" && (
          <div className="row">
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                PostedByNetwork Daily Billable{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <PostedBillableDaily table={table + "/countPostedDaily"} />
              </div>
            </div>

            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                PostedByNetwork Daily Non-Billable{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <PostedNonBillableDaily
                  table={table + "/countPostedDailyNonBillable"}
                />
              </div>
            </div>
          </div>
        )}

        {role !== "accountant" && (
          <div className="row">
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                PostedByNetwork Monthly Billable{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <PostedMonthly table={table} />
              </div>
            </div>

            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                PostedByNetwork Monthly Non-Billable{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <PostedMonthlyNonBillable table={table + "/nonbillable"} />
              </div>
            </div>
          </div>
        )}

        {role !== "accountant" && (
          <div className="row">
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Response Daily{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <OperatorResponseDaily table={table + "/operatorresponse"} />
              </div>
            </div>

            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Response By Date{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <OperatorResponseMonthly
                  table={table + "/operatorresponsebydate"}
                />
              </div>
            </div>
          </div>
        )}

        {role !== "accountant" && (
          <div className="row">
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Daily Active By Operator{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <CountDailySubByOperator
                  table={table + "/countDailySubOperator"}
                />
              </div>
            </div>
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Daily Posted By Operator{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <CountDailyPostedByOperator
                  table={table + "/countDailyPostedOperator"}
                />
              </div>
            </div>
          </div>
        )}


        {role !== "accountant" && (
          <div className="row">
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Non-Billable Active
                <span style={{ color: randomColor }}>
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <ActiveNonBillable table={table + "/nonbillableactive"} />
              </div>
            </div>

            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Sub History
                <span style={{ color: randomColor }}>
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <SubHistory table={table + "/subHistory"} />
              </div>
            </div>
          </div>
        )}

        {/* MobIdea Accountant Role */}

        {role === "accountant" && (
          <div className="row">
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Sub History{" "}
                <span style={{ color: randomColor }}>
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <SubHistoryAll table={table + "/allSubHistory"} />
              </div>
            </div>

            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Message Count{" "}
                <span style={{ color: randomColor }}>
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <SubHistory table={table + "/subHistory"} />
              </div>
            </div>
          </div>
        )}
        {/* MobIdea Accountant Role */}
        {role !== "accountant" && (
          <div className="row">
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                All Sub{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <AllSub table={table + "/networksClicksId"} />
              </div>
            </div>

            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Weekly Pin Req & Con{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <CountWeeklyPinReqCon
                  table={table + "/countWeeklyPinRequePinRequestconfirm"}
                />
              </div>
            </div>
          </div>
        )}


        {role !== "accountant" && role !== "admin" ? (
          <div className="row">
            <div className="col-5 shadow ml-5">
              <h2 className="text-secondary font-weight-bold">
                Weekly Visitors{" "}
                <span style={{ color: randomColor }}>
                  {" "}
                  {table.toUpperCase()}
                </span>
              </h2>
              <div>
                <WeeklyVisitors table={table + "/countWeeklyVisits"} />
              </div>
            </div>

            {table === "islamnquran" ? (
              <div className="col-5 shadow ml-5">
                <h2 className="text-secondary font-weight-bold">
                  Info CPI{" "}
                  <span style={{ color: randomColor }}>
                    {" "}
                    {table.toUpperCase()}
                  </span>
                </h2>
                <div>
                  <InfoCPI table={table + "/infocpi"} />
                </div>
              </div>
            ) :
              <div className="col-5 shadow ml-5">
                <h2 className="text-secondary font-weight-bold">
                  Renew And SUB{" "}
                  <span style={{ color: randomColor }}>
                    {" "}
                    {table.toUpperCase()}
                  </span>
                </h2>
                <div>
                  <SubAndRenew table={table + "/subAndRenew"} />
                </div>
              </div>
            }
          </div>
        ) : null}

        {/* End */}
      </div>
    </div>
  );
};

export default SubWidget;
