// mysql -u root -p -e 'flush hosts'
// Lebanon@2018@Lebanon
import { useContext, useRef, useState } from "react";
import "./login.css";
import logo from "./../../images/mmt.png";
import { Link } from "react-router-dom";
import SessionContext from "../session/SessionContext";
import { Routes, Route } from "react-router-dom";
import ForgetPassword from "../forget_verfication_new_Password/ForgetPassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const Login = () => {
  const [showOpenEyeIcon, setShowOpenEyeIcon] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const {
    actions: { signin },
    session,
  } = useContext(SessionContext);
  const [alert, setAlert] = useState(false);
  // console.log('session',session.user.wrongemailOrPassword)
  const updateState = (nextSession) => {
    setState((prevSession) => ({
      ...prevSession,
      ...nextSession,
    }));
  };
  // console.log(state)

  const handleChange = (e) => {
    let { name, value } = e.target;
    updateState({ [name]: value });
  };
  const handleLogin = (e) => {
    e.preventDefault();
    console.log("state", state)

    signin(state);
    if (session.user.wrongemailOrPassword) {
      setAlert(true);
    }
  };

  const handleClickIconEye = () => {
    const input = document.querySelector("#password");
    if (input.type === "password") {
      setShowOpenEyeIcon(true);
      input.type = "text";
    } else {
      setShowOpenEyeIcon(false);
      input.type = "password";
    }
  };
  return (
    <>
      <div id="login-form-wrap">
        <div className="d-flex justify-content-center p-3">
          <img className="logo" src={logo} />
        </div>
        {alert && (
          <div className="alert alert-danger" role="alert">
            email and passowrd does not mateched, try again
          </div>
        )}
        <form id="login-form" onSubmit={handleLogin}>
          <div>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="example@some.com"
              required
              onChange={handleChange}
            />
          </div>
          <div>
            <FontAwesomeIcon
              icon={showOpenEyeIcon ? faEye : faEyeSlash}
              className="eye-icon"
              onClick={handleClickIconEye}
            />
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              required
              onChange={handleChange}
            />
          </div>
          {/* <div className='forget-password pb-3'>
            <Link to={'/forget-password'}>Forget Password?</Link>
          </div> */}
          <div>
            <button className="mb-5 login-button" type="submit" id="login">
              {" "}
              Login
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
export default Login;
