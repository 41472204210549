import * as React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

import { advertisingBillablePostedColumns } from "./datatablesource";
import { useEffect, useState } from "react";

import { fetchUrl } from "../../functions/fetchUrl";
import fetchURLForm from "../../functions/fetchURLForm";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Input from "@mui/joy/Input";
import Box from "@mui/material/Box";
import numeral from "numeral";

const AdvertisingBillablePosted = ({ table }) => {
  useState("Loading...");
  const today = new Date();

  var fileDate =
    today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate();

  var fileName = "";

  if (table === "services/allbillablepostedtotal") {
    fileName = `AdvertisingBillablePosted_${fileDate}`;
  } else if (table === "services/allnonbillablepostedtotal") {
    fileName = `AdvertisingNonBillablePosted_${fileDate}`;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ allColumns: true, fileName }} />
      </GridToolbarContainer>
    );
  }

  const [data, setData] = useState([]);
  const [valueDateFrom, setValueDateFrom] = useState(today);
  const [valueDateTo, setValueDateTo] = useState(today);
  const [payout, setPayout] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setData([]);
    //     "valueDateTo": "01-20-2023"

    const monthFromNumber = (valueDateFrom.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const dayFromNumber = valueDateFrom.getDate().toString().padStart(2, "0");
    const yearFromNumber = valueDateFrom.getFullYear();

    const monthToNumber = (valueDateTo.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const dayToNumber = valueDateTo.getDate().toString().padStart(2, "0");
    const yearToNumber = valueDateTo.getFullYear();

    const dateFrom =
      monthFromNumber + "-" + dayFromNumber + "-" + yearFromNumber;

    const dateTo = monthToNumber + "-" + dayToNumber + "-" + yearToNumber;

    const bodySend = {
      valueDate: dateFrom,
      valueToDate: dateTo,
      payout: payout,
    };

    const fetchData = async () => {
      const response = await fetchUrl({
        apiName: table,
        method: "POST",
        reqBody: bodySend
      });
      if (response.success) {
        // console.log(response.data);
        setData(response.result.Bill);
        setTotal(response.result.Total);
      }
    };
    fetchData();
  }, [payout, table, valueDateFrom, valueDateTo]);

  return (
    <div
      className="col-10 "
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "130px",
        marginLeft: "190px",
      }}
    >
      <div className="col shadow ml-5">
        <div>
          <div>
            <Box style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From:"
                    value={valueDateFrom}
                    onChange={(newValue) => {
                      setValueDateFrom(new Date(newValue));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To:"
                    value={valueDateTo}
                    onChange={(newValue) => {
                      setValueDateTo(new Date(newValue));
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label htmlFor="nameInput">Payout:</label>
                <Input
                  type="text"
                  defaultValue={1}
                  startDecorator={"$"}
                  onChange={(event) => {
                    const value = event.target.value;
                    const formattedValue = value
                      .replace(/[^0-9 ]/g, "") // Only allow digits and spaces
                      .trim(); // Remove leading/trailing spaces
                    setPayout(formattedValue);
                  }}
                />
              </div>
            </Box>

            <div>
              <DataGrid
                className="ml-1"
                rows={data}
                columns={advertisingBillablePostedColumns}
                style={{ height: "75vh", marginLeft: "50px" }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                getRowId={(data) => data.id}
              />
              <div
                style={{
                  fontSize: "24px",
                  color: "green",
                  marginLeft: "600px",
                }}
              >
                Total: {numeral(total).format("0,0.00")} $
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertisingBillablePosted;
