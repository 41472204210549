import { useEffect, useState } from "react";
import fetchUrlForm from "../../../../functions/fetchURLForm";
import ChartCircle from "./charts/ChartCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import spinnerGif from "../../../../images/spinner.gif";
import { fetchUrl } from "../../../../functions/fetchUrl";

const CountDailyPostedByOperator = ({ table }) => {
  const [orderData, setOrderData] = useState({
    success: true,
    result: {
      STC: 0,
      Mobily: 0,
      Zain: 0,
    },
  });
  const [fetching, setfetching] = useState("fetching");
  useEffect(() => {
    setfetching("fetching")
    setOrderData({
      success: true,
      result: {
        STC: 0,
        Mobily: 0,
        Zain: 0,
      },
    });
    const fetchData = async () => {
      const response = await fetchUrl({
        apiName: table,
        method: "GET",
      });

      if (response.success) {
        setOrderData(response);
        setfetching("complete")
      }
    };
    fetchData();
  }, [table]);

  return (
    <div>
      {/* Orders card  */}
      <div className="col-5">
        <div
          className="shadow  p-2"
          style={{ width: "400px", height: "450px" }}
        >
          <div>
            {/* <span className='font-weight-bold'><u>Orders:</u></span> */}
            <div className="d-flex justify-content-betweenalign-items-center">
              <div>
                <FontAwesomeIcon
                  icon={faSquare}
                  className="pl-1"
                  style={{ color: "rgb(54, 162, 235)" }}
                />
                <small>STC</small>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faSquare}
                  className="pl-1"
                  style={{ color: "#0f0" }}
                />
                <small>Mobily</small>
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faSquare}
                  className="pl-1"
                  style={{ color: "#f00" }}
                />
                <small>Zain</small>
              </div>
            </div>
          </div>
          <div className="p-3 mt-4">
            {fetching === 'fetching' ? (
              <div>
                <img
                  src={spinnerGif}
                  alt="Loading..."
                  style={{
                    width: "100px",
                    height: "100px",
                    marginLeft: "130px",
                    marginTop: "90px",
                  }}
                />
              </div>
            ) : (
              <ChartCircle
                chartLabel={["Total STC", "Total Mobily", "Total Zain"]}
                nameOfChart={"Users"}
                NumberOfTotal={[
                  orderData.result.STC > 0 ? orderData.result.STC : 1,
                  orderData.result.Mobily > 0 ? orderData.result.Mobily : 1,
                  orderData.result.Zain > 0 ? orderData.result.Zain : 1,
                ]}
                colorOfTotal={["rgb(54, 162, 235)", "#0f0", "#f00"]}
                borderColor={["rgb(54, 162, 235)", "#0f0", "#f00", "#0fff"]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountDailyPostedByOperator;
