import { DataGrid } from "@mui/x-data-grid";
import { postedDailyColumns } from "../../datatablesource";
import { useEffect, useState } from "react";

import { fetchUrl } from "../../../../functions/fetchUrl";
import fetchURLForm from "../../../../functions/fetchURLForm";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const PostedMonthly = ({ table }) => {
  useState("Loading...");
  const today = new Date();

  const [data, setData] = useState([]);
  const [valueDateFrom, setValueDateFrom] = useState(today);
  const [valueDateTo, setValueDateTo] = useState(today);

  useEffect(() => {
    setData([]);
    //     "valueDateTo": "01-20-2023"

    const monthFromNumber = (valueDateFrom.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const dayFromNumber = valueDateFrom.getDate().toString().padStart(2, "0");
    const yearFromNumber = valueDateFrom.getFullYear();

    const monthToNumber = (valueDateTo.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const dayToNumber = valueDateTo.getDate().toString().padStart(2, "0");
    const yearToNumber = valueDateTo.getFullYear();

    const dateFrom =
      monthFromNumber + "-" + dayFromNumber + "-" + yearFromNumber;

    const dateTo = monthToNumber + "-" + dayToNumber + "-" + yearToNumber;

    const bodySend = {
      valueDate: dateFrom,
      valueToDate: dateTo,
    };

    const fetchData = async () => {
      const response = await fetchUrl({
        apiName: table,
        method: "POST",
        reqBody: bodySend
      });
      if (response.success) {
        setData(response.result);
      }
    };
    fetchData();
  }, [table, valueDateFrom, valueDateTo]);

  return (
    <div>
      <div>
        <div style={{ display: "inline-block", margin: "0 10px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From:"
              value={valueDateFrom}
              onChange={(newValue) => {
                setValueDateFrom(new Date(newValue));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div style={{ display: "inline-block", margin: "0 10px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To:"
              value={valueDateTo}
              onChange={(newValue) => {
                setValueDateTo(new Date(newValue));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div>
          <DataGrid
            className="ml-1"
            rows={data}
            columns={postedDailyColumns}
            pageSize={15}
            rowsPerPageOptions={[5]}
            style={{ height: "60vh", marginLeft: "50px" }}
            getRowId={(data) => data.id}
          />
        </div>
      </div>
    </div>
  );
};

export default PostedMonthly;
