import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { subAndRenew } from "../../datatablesource";
import { useEffect, useState } from "react";

import fetchURLForm from "../../../../functions/fetchURLForm";
import { fetchUrl } from "../../../../functions/fetchUrl";


import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem"

function CustomToolbar({ table, operatorSelectedValue, typeSelectedValue }) {
  var today = new Date();
  today = today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate();
  const tableName = table.split("/").shift();
  const fileName = `Report_${tableName}_${operatorSelectedValue}_${typeSelectedValue}_${today}`;
  return (
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={{ allColumns: true, fileName }} />
    </GridToolbarContainer>
  );
}
const SubAndRenew = ({ table }) => {
  useState("Loading...");
  const today = new Date();

  console.log('Table: ' + table);

  const [data, setData] = useState([]);
  const [valueDateFrom, setValueDateFrom] = useState(today);
  const [valueDateTo, setValueDateTo] = useState(today);
  const [operatorSelectedValue, setOperatorSelectedValue] = useState("ksastc");
  const [typeSelectedValue, setTypeSelectedValue] = useState("sub");

  // const [requestText, setRequestText] = useState("");

  const operatorHandleChange = (event) => {
    setOperatorSelectedValue(event.target.value);
  };

  const typeHandleChange = (event) => {
    setTypeSelectedValue(event.target.value);
  };

  useEffect(() => {
    setData([]);
    //     "valueDateTo": "01-20-2023"

    const monthFromNumber = (valueDateFrom.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const dayFromNumber = valueDateFrom.getDate().toString().padStart(2, "0");
    const yearFromNumber = valueDateFrom.getFullYear();

    const monthToNumber = (valueDateTo.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const dayToNumber = valueDateTo.getDate().toString().padStart(2, "0");
    const yearToNumber = valueDateTo.getFullYear();

    const dateFrom =
      monthFromNumber + "-" + dayFromNumber + "-" + yearFromNumber;

    const dateTo = monthToNumber + "-" + dayToNumber + "-" + yearToNumber;

    const bodySend = {
      valueDate: dateFrom,
      valueToDate: dateTo,
      requestText: operatorSelectedValue,
      type: typeSelectedValue,
    };

    const fetchData = async () => {
      const response = await fetchUrl({
        apiName: table,
        method: "POST",
        reqBody: bodySend
      });
      if (response.success) {
        setData(response.result);
      }
    };
    fetchData();
  }, [table, valueDateFrom, valueDateTo, operatorSelectedValue, typeSelectedValue]);

  return (
    <div>
      <div>
        <Box
          component="form"
          sx={{
            display: 'flex',
            "& > :not(style)": { m: 1, width: "40ch" },
          }}
          noValidate
          autoComplete="on"
        >
          <Select
            value={operatorSelectedValue}
            onChange={operatorHandleChange}
            inputProps={{
              style: {
                height: "10px",
              },
            }}
          >
            <MenuItem value="ksastc">KSA STC</MenuItem>
            <MenuItem value="ksazain">KSA ZAIN</MenuItem>
            <MenuItem value="ksamobily">KSA MOBILY</MenuItem>
          </Select>
          <Select
            label="Search Response ..."
            value={typeSelectedValue}
            onChange={typeHandleChange}
            inputProps={{
              style: {
                height: "10px",
              },
            }}
          >
            <MenuItem value="sub">SUB</MenuItem>
            <MenuItem value="renew">RENEW</MenuItem>
            <MenuItem value="unsub">UNSUB</MenuItem>
          </Select>
        </Box>
        <div style={{ display: "flex", gap: '10px', margin: "5px 10px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From:"
              value={valueDateFrom}
              onChange={(newValue) => {
                setValueDateFrom(new Date(newValue));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          {/* </div>
        <div style={{ display: "flex", margin: "0 10px" }}> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To:"
              value={valueDateTo}
              onChange={(newValue) => {
                setValueDateTo(new Date(newValue));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div>
          <DataGrid
            className="ml-1"
            rows={data}
            columns={subAndRenew}
            pageSize={15}
            rowsPerPageOptions={[5]}
            style={{ height: "60vh", marginLeft: "50px" }}
            components={{
              Toolbar: () => <CustomToolbar table={table} operatorSelectedValue={operatorSelectedValue}
                typeSelectedValue={typeSelectedValue} />,
            }}
            getRowId={(data) => data.id}
          />
        </div>
      </div>
    </div>
  );
};

export default SubAndRenew;
