import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// import axios from "axios";
import { positions, transitions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 2000,
  transitions: transitions.SCALE,
};

// axios.defaults.baseURL = "https://api.dashboard.mobimartech.com:3456/";
// axios.defaults.baseURL = "https://apidashboard.mobimartech.com";
// axios.defaults.baseURL = "http://api.dashboard.mobimartech.com";
// axios.defaults.baseURL = "http://localhost:3456/";
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AlertProvider template={AlertTemplate} {...options}>
        <App />
      </AlertProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
