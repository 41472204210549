import { getCookie } from "./cookie";

const fetchURLForm = async (urll, method, data) => {
    let url = "https://apidashboard.mobimartech.com/" + urll;
    let responseData;
    const id = getCookie("id");
    const token = getCookie("token");

    const headers = {
        "Content-Type": "application/json",
        id,
        token,
    };

    try {
        const response = await fetch(url, {
            method,
            headers,
            body: data ? JSON.stringify(data) : undefined,
        });

        // Mimic axios by trying to parse JSON response data
        const parsedData = await response.json();

        // Creating an object similar to axios response format
        responseData = {
            data: parsedData,
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            config: { url, method, headers, data },
        };
    } catch (error) {
        console.log(error);
        responseData = error;
    }
    return responseData;
};

export default fetchURLForm;
