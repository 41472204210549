// https://fontawesome.com/search?q=
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell } from "@fortawesome/free-solid-svg-icons";
import {
  faFutbol,
  faShield,
  faMosque,
  faSeedling,
  faGift,
  faNetworkWired,
  faMoneyCheckDollar,
  faGlobe,
  faBus,
  faScissors,
  faFileInvoiceDollar,
  faFileInvoice,
  faPaw,
  faBook,
  faHatCowboy,
  faUserSecret,
  faFileShield,
  faTrophy,
  faBroom,
  faComment,
  faBasketball,
  faRobot,
  faBowlFood

} from "@fortawesome/free-solid-svg-icons";

// import { faHatChef } from '@fortawesome/sharp-solid-svg-icons'

import { faGamepad } from "@fortawesome/free-solid-svg-icons";

import "./navbar.css";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import SessionContext from "../session/SessionContext";

const SuperAdminSidebar = () => {
  const {
    actions: { signout },
  } = useContext(SessionContext);
  return (
    <div
      className=" border-right h-100 "
      id="sidebar"
      style={{
        width: "250px",
        padding: "0 10px",
        position: "fixed",
        top: "81px",
        left: "0",
        zIndex: "9999",
        backgroundColor: "#fff",
        overflow: "auto",
        paddingBottom: "50px",
      }}
    >
      <ul className="list-unstyled ">
        {/* <NavLink to={"storeapproval"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faGlobe} className="text-primary pr-3" />
              Stores
            </p>
          </li>
        </NavLink>
        <NavLink to={"categories"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faGlobe} className="text-primary pr-3" />
              Categories
            </p>
          </li>
        </NavLink>
        <NavLink to={"store"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faGlobe} className="text-primary pr-3" />
              Store
            </p>
          </li>
        </NavLink> */}
        <NavLink to={"services"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faGlobe} className="text-primary pr-3" />
              Landing Pages
            </p>
          </li>
        </NavLink>

        <NavLink to={"advertisement"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faGlobe} className="text-primary pr-3" />
              Advertising
            </p>
          </li>
        </NavLink>

        <NavLink to={"billableposted"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faMoneyCheckDollar}
                className="text-primary pr-3"
              />
              Billable Posted
            </p>
          </li>
        </NavLink>

        <NavLink to={"nonbillableposted"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faMoneyCheckDollar}
                className="text-primary pr-3"
              />
              Non-Billable Posted
            </p>
          </li>
        </NavLink>

        {/* <NavLink to={"billablepostedbynetwork"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faMoneyCheckDollar}
                className="text-primary pr-3"
              />
              Billable Posted By Network
            </p>
          </li>
        </NavLink> */}

        <NavLink to={"advertisingnetwork"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faNetworkWired}
                className="text-primary pr-3"
              />
              Advertising Networks
            </p>
          </li>
        </NavLink>

        {/* <NavLink to={"AdvertisingInvoices"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faFileInvoiceDollar}
                className="text-primary pr-3"
              />
              Advertising Invoices
            </p>
          </li>
        </NavLink> */}

        {/* <NavLink to={"AdvertisingOffers"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faFileInvoiceDollar}
                className="text-primary pr-3"
              />
              Advertising Offers
            </p>
          </li>
        </NavLink> */}

        <NavLink to={"AdvertisingBillablePosted"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faFileInvoiceDollar}
                className="text-primary pr-3"
              />
              Advertising Billable Posted
            </p>
          </li>
        </NavLink>

        <NavLink to={"AdvertisingNonBillablePosted"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faFileInvoiceDollar}
                className="text-primary pr-3"
              />
              Advert Non-Billable Posted
            </p>
          </li>
        </NavLink>

        <NavLink to={"bnfootball"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faFutbol} className="text-primary pr-3" />
              BnFootball
            </p>
          </li>
        </NavLink>

        <NavLink to={"koraclubtimwe"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faFutbol} className="text-primary pr-3" />
              KoraClub OM BH TimWe
            </p>
          </li>
        </NavLink>

        <NavLink to={"converseaitimwe"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faComment} className="text-primary pr-3" />
              Converse OM BH TimWe
            </p>
          </li>
        </NavLink>

        <NavLink to={"chefrecipestimwe"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faBowlFood} className="text-primary pr-3" />
              ChefRecipes OM TimWe
            </p>
          </li>
        </NavLink>


        <NavLink to={"scoretimwe"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faBasketball} className="text-primary pr-3" />
              Score TimWe
            </p>
          </li>
        </NavLink>

        <NavLink to={"beautytimwe"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faScissors} className="text-primary pr-3" />
              Beauty TimWe
            </p>
          </li>
        </NavLink>

        <NavLink to={"assistanttimwe"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faRobot} className="text-primary pr-3" />
              Assistant TimWe
            </p>
          </li>
        </NavLink>

        <NavLink to={"extratime"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faFutbol} className="text-primary pr-3" />
              ExtraTime365
            </p>
          </li>
        </NavLink>

        <NavLink to={"shootkoora"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faFutbol} className="text-primary pr-3" />
              Shoot Koora
            </p>
          </li>
        </NavLink>

        <NavLink to={"filesguard"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faShield} className="text-primary pr-3" />
              FilesGuard
            </p>
          </li>
        </NavLink>

        <NavLink to={"filesheild"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faShield} className="text-primary pr-3" />
              FileSheild
            </p>
          </li>
        </NavLink>

        <NavLink to={"sheild"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faFileShield}
                className="text-primary pr-3"
              />
              Sheild 3ANET
            </p>
          </li>
        </NavLink>

        <NavLink to={"gamesportal"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faGamepad} className="text-primary pr-3" />
              GamesPortal
            </p>
          </li>
        </NavLink>

        <NavLink to={"gombsgom"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faGamepad} className="text-primary pr-3" />
              GomBsGom
            </p>
          </li>
        </NavLink>
        <NavLink to={"gymnslim"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faDumbbell}
                className="text-primary pr-3"
              />
              GymNSLim
            </p>
          </li>
        </NavLink>
        <NavLink to={"islamicnote"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faMosque} className="text-primary pr-3" />
              IslamicNote
            </p>
          </li>
        </NavLink>
        <NavLink to={"alsirat"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faMosque} className="text-primary pr-3" />
              Al Sirat
            </p>
          </li>
        </NavLink>
        <NavLink to={"plantid"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faSeedling} className="text-primary pr-3" />
              PlantId
            </p>
          </li>
        </NavLink>
        <NavLink to={"womencare"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faScissors} className="text-primary pr-3" />
              Women Care
            </p>
          </li>
        </NavLink>
        <NavLink to={"foodrecipes"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faHatCowboy} className="text-primary pr-3" />
              Food Recipes
            </p>
          </li>
        </NavLink>
        <NavLink to={"islamicreminder"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faMosque} className="text-primary pr-3" />
              Islamic Reminder
            </p>
          </li>
        </NavLink>
        <NavLink to={"koraclub"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faFutbol} className="text-primary pr-3" />
              KoraClub
            </p>
          </li>
        </NavLink>



        <NavLink to={"mobileboxes"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faGift} className="text-primary pr-3" />
              MobileBoxes
            </p>
          </li>
        </NavLink>
        <NavLink to={"mobofort"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faShield} className="text-primary pr-3" />
              Mobofort
            </p>
          </li>
        </NavLink>
        <NavLink to={"muslimsgate"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faMosque} className="text-primary pr-3" />
              MuslimsGate
            </p>
          </li>
        </NavLink>
        <NavLink to={"islamnquran"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faMosque} className="text-primary pr-3" />
              IslamNQuran
            </p>
          </li>
        </NavLink>
        <NavLink to={"smsmapp"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faShield} className="text-primary pr-3" />
              SmsmApp
            </p>
          </li>
        </NavLink>
        <NavLink to={"vipgym"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faDumbbell}
                className="text-primary pr-3"
              />
              VipGym
            </p>
          </li>
        </NavLink>
        <NavLink to={"whatsfile"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faShield} className="text-primary pr-3" />
              WhatsFile
            </p>
          </li>
        </NavLink>

        <NavLink to={"dailybeauty"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faScissors}
                className="text-primary pr-3"
              />
              Daily Beauty
            </p>
          </li>
        </NavLink>

        <NavLink to={"kidspreschool"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faBus} className="text-primary pr-3" />
              KidsPreSchool
            </p>
          </li>
        </NavLink>

        <NavLink to={"pets"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faPaw} className="text-primary pr-3" />
              Pets
            </p>
          </li>
        </NavLink>

        <NavLink to={"oxford"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon icon={faBook} className="text-primary pr-3" />
              Oxford
            </p>
          </li>
        </NavLink>

        <NavLink to={"chefRecipes"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faHatCowboy}
                className="text-primary pr-3"
              />
              ChefRecipes
            </p>
          </li>
        </NavLink>
        <NavLink to={"securesafe"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faUserSecret}
                className="text-primary pr-3"
              />
              SecureSafe
            </p>
          </li>
        </NavLink>
        <NavLink to={"subAndWin"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faTrophy}
                className="text-primary pr-3"
              />
              SubAndWin
            </p>
          </li>
        </NavLink>
        <NavLink to={"phoneCleaner"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faBroom} beat
                className="text-primary pr-3"
              />
              Phone Cleaner
            </p>
          </li>
        </NavLink>
        <NavLink to={"converseAI"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faComment} fade
                className="text-primary pr-3"
              />
              Converse AI
            </p>
          </li>
        </NavLink>
        <NavLink to={"stickyNote"}>
          <li className="py-2 ">
            <p className="text-dark pl-3 font-weight-bold pt-1" href="#">
              <FontAwesomeIcon
                icon={faComment} fade
                className="text-primary pr-3"
              />
              Sticky Note
            </p>
          </li>
        </NavLink>
      </ul>
      <button className="btn btn-dark mb-5 ml-4 " onClick={signout}>
        LOGOUT
      </button>
    </div>
  );
};

export default SuperAdminSidebar;
