// require('dotenv').config();
// let HOST_URL = process.env.REACT_APP_HOST_URL;

import { getCookie, removeCookie } from "./cookie";

/**
 * Used to make it easy to fetch from backend
 * @param {string}
 * @returns
 */

export async function fetchUrl({ method, apiName, reqBody }) {
  // let url = "http://localhost:3456/" + apiName;
  let url = "https://apidashboard.mobimartech.com/" + apiName;
  console.log("Fetching from URL:", url);

  let id = getCookie("id");
  let token = getCookie("token");
  let header = { id, token };

  let headers = {
    "Content-Type": "application/json",
  };

  // let requests = {
  //   method,
  //   headers,
  //   // mode: 'no-cors',
  //   body: reqBody ? JSON.stringify(reqBody) : undefined,
  // };

  let requests;
  if (reqBody) {
    requests = {
      method,
      headers,
      // mode: 'no-cors',
      body: JSON.stringify(reqBody)
    };
    console.log("Request Body:", JSON.stringify(reqBody));

  } else {
    requests = {
      method,
      headers,
    };
  }

  console.log("Request:", JSON.stringify(requests));


  try {
    let response = await fetch(url, requests);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    let data = await response.json();
    console.log("Response Data:", data);
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
    return { success: false, error: error.message };
  }
}


// export async function fetchUrl({ method, apiName, reqBody }) {
// let data = {};
// // let url = "https://api.dashboard.mobimartech.com:3456/" + apiName;
// let url = "http://localhost:3456/" + apiName;

// console.log(url)

// let id = getCookie("id");
// let token = getCookie("token");
// let header = { id, token };

// let headers = {
//   ...header,
// };

// if (reqBody)
//   headers = {
//     "Content-Type": "application/json",
//     ...header,
//   };

// let requests = {
//   method,
//   mode: 'no-cors',
//   headers,
//   body: JSON.stringify(reqBody),
// };

// console.log(JSON.stringify(reqBody))

// removeCookie(id);
// // let response = await fetch(url, requests);
// // data = await response.json();
// // console.log(data)


// try {
//   let response = await fetch(url, requests);

//   if (!response.ok) {
//     throw new Error(`HTTP error! Status: ${response.status}`);
//   }

//   data = await response.json();
//   console.log("Response Data:", data);
// } catch (error) {
//   console.error("Fetch error:", error);
// }

// return data;
// }
