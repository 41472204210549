import "./header.css";
import logo from "./../../images/mmt.png";
import { useEffect, useState } from "react";
import { fetchUrl } from "../../functions/fetchUrl";
import fetchURLForm from "../../functions/fetchURLForm";
import mmtlogo from "../../images/mmt.png";

const Header = ({ title, name, imageUser }) => {
  const imageURL = "https://dashboard.mobimartech.com:3456/images/";

  const [dataOfDropdown, setDataOfDropdown] = useState(null);
  const [dataOfDropdown1, setDataOfDropdown1] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchUrl({
        apiName: "admin/lastlogged",
        method: "GET"
      });
      if (response.success) {
        setDataOfDropdown(response.result[0].name);
        if (response.result[0].created_at) {
          setDataOfDropdown1(
            response.result[0].created_at
              .replace(".000Z", " ")
              .replace("T", " ")
          );
        } else {
          console.warn("created_at is undefined in the response");
        }
      }
    };
    fetchData();
    // const response = await fetchUrl({
    //   apiName: "admin/lastlogged",
    //   method: "GET"
    // });
    // if (response.success) {
    //   setDataOfDropdown(response.result[0].name);
    //   setDataOfDropdown1(
    //     response.data.result[0].created_at
    //       .replace(".000Z", " ")
    //       .replace("T", " ")
    //   );
    // }
  }, []);

  // console.log("Response", dataOfDropdown)
  return (
    <div
      className="border-bottom"
      style={{
        position: "fixed",
        left: "0",
        top: "0",
        width: "100vw",
        backgroundColor: "#fff",
        zIndex: 9999,
      }}
    >
      <div className="wrapp-header mx-5">
        <img className="logo" src={logo} />

        {/* <h7 className="text" >{title} </h7> */}
        {title === "superadmin" ? (
          <h5 className="text">
            {title}
            <br></br>Last updated by, {dataOfDropdown}{" "}
          </h5>
        ) : (
          <h3 className="text">MMT </h3>
        )}

        {/* <h2 className="text-secondary font-weight-bold" >{name}</h2> */}
        <figure>
          <img className="logo" src={`${mmtlogo}`} alt="" />
          <figcaption> {name}</figcaption>
        </figure>
      </div>
    </div>
  );
};

export default Header;
