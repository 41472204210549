import { useRef, useState } from "react";
import fetchURLForm from "../../../functions/fetchURLForm";
import { fetchUrl } from "../../../functions/fetchUrl";

const AddAdvertisingNetwork = ({ showAddStoreBox }) => {
  const name = useRef("");
  const shortname = useRef("");
  const postbackurl = useRef("");

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSave = async (event) => {
    event.preventDefault();
    // const body = new FormData();

    // body.append("name", name.current.valueOf);
    // body.append("shortname", shortname.current.valueOf);
    // body.append("postbackurl", postbackurl.current.valueOf);

    const bodySend = {
      name: document.getElementById('name').value,
      shortname: document.getElementById('shortname').value,
      postbackurl: document.getElementById('postbackurl').value,
    };

    const response = await fetchUrl({
      apiName: "services/addadvertisingnetwork",
      method: "POST",
      reqBody: bodySend
    });
    if (response.success) {
      setSuccess(true);
      setTimeout(() => {
        showAddStoreBox();
      }, 300);
    } else {
      setError(true);
      console.log(response);
    }
  };
  const hideAlert = () => {
    setSuccess(false);
    setError(false);
  };

  return (
    <div
      id="addNoteModal"
      className="modal"
      style={{
        zIndex: "999",
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>Add New Service</h2>
        </div>
        <div>
          {success && (
            <div className="alert alert-success" role="alert">
              Created Successfully...
            </div>
          )}
          {error && (
            <div className="alert alert-anger" role="alert">
              Created Failed Try Again...
            </div>
          )}
        </div>
        <form
          className="w-100 p-5"
          encType="multipart/form-data"
          onSubmit={handleSave}
        >
          <div className="mt-2">
            <label htmlFor="name">Network Name</label>
            <input
              ref={name}
              type="text"
              id="name"
              placeholder="Network Name"
              className="form-control"
              required
              onChange={hideAlert}
            />
          </div>

          <div className="mt-2">
            <label htmlFor="shortname">Short Name</label>
            <input
              ref={shortname}
              type="text"
              id="shortname"
              placeholder="Short Name"
              className="form-control"
              required
              onChange={hideAlert}
            />
          </div>

          <div className="mt-2">
            <label htmlFor="postbackurl">Posted Back URL</label>
            <input
              ref={postbackurl}
              type="text"
              id="postbackurl"
              placeholder="Posted Back URL"
              className="form-control"
              required
              onChange={hideAlert}
            />
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-primary">
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={showAddStoreBox}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddAdvertisingNetwork;
